import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { getBussinessReviews } from "../services/businessReviews";
import { ReviewCard } from "./UI/ReviewCard";
import { useNavigate } from "react-router-dom";
import AddReviewModal from "./AddReviewModal";

const BussinessReviewsSection = () => {
  const [reviews, setReviews] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [addreviewModalVisible, setReviewModalVisible] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    const getReviews = async () => {
      await getBussinessReviews().then((res) => {
        const sortedReviews = res
          .filter((review) => review.status === "approved")
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 3);
        setReviews(sortedReviews);
        // setReviews((prev) => [...prev, ...res, ...res]);
        // console.log(reviews);
      });
    };
    getReviews();
  }, [refreshData]);

  return (
    <div
      className=" py-5 "
      style={{
        // backgroundColor: "#fcfcf5",
        background: "linear-gradient(135deg, #e1f3f7, #c2ebff)",
        // backgroundColor: "#e1f3f7 ,#94d6f7"
      }}
    >
      <p className=" h1 text-center">Reviews</p>
      {/* <p>Business reviews will go here</p> */}
      {reviews.length >= 1 ? (
        <>
          <div className="d-flex flex-row flex-wrap justify-content-center">
            {reviews.map((review) => (
              <div
                className="d-flex flex-column"
                style={{ margin: "20px" }}
                key={review.id}
              >
                <ReviewCard review={review} />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center  ">
            <Button
              className="text-white px-5 py-3 background-tertiary border-0"
              label="View All"
              style={{
                // background: "linear-gradient(135deg,#219EBC, #023047 )",
                borderRadius: "200px",
              }}
              onClick={() => {
                navigate("/reviews");
              }}
              // icon="pi pi-plus"
              // onClick={() => (true)}
            />
          </div>
        </>
      ) : (
        <div className="my-3">
          <p className="fs-6 text-center my-5">No Reviews Yet.</p>
          <div className="d-flex justify-content-center">
            <Button
              className=" mt-2 "
              label="Leave a Review"
              style={{ borderRadius: "200px" }}
              // icon="pi pi-plus"
              onClick={() => setReviewModalVisible(true)}
            />
          </div>
        </div>
      )}
      <AddReviewModal
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        visible={addreviewModalVisible}
        setVisible={setReviewModalVisible}
      />
    </div>
  );
};

export default BussinessReviewsSection;
