import React, { useState, useEffect, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { getSettings, updateSetting } from "../services/settings.js";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { snakeToPascal } from "../Components/custom/Helpers.js";
import { FaPlus } from "react-icons/fa";
import "../styles/AllSettings.css";
import { NewCustomListing } from "../Components/custom/NewCustomListing.jsx";
import { Can } from "../configs/Ability-context.js";
import axios from "axios";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";

const AllSettings = () => {
  const dt = useRef(null);
  const [state, setState] = useState({
    allSettings: [],
    isLoading: false,
    refreshData: false,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });

  const { allSettings, isLoading, refreshData, activeEditor, editorText } =
    state;

  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getSettings();
        setState((prevState) => ({
          ...prevState,
          allSettings: data,
          isLoading: false,
        }));
      } catch (error) {
        console.log(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
  }, [refreshData]);

  const testEmailConnection = (emailSettings) => {
    axios
      .post("/api/settings/testEmail", {
        emailServer: emailSettings.find((email) => email.name === "email_host")
          .value,
        emailPort: emailSettings.find((email) => email.name === "email_port")
          .value,
        emailUser: emailSettings.find(
          (email) => email.name === "email_username"
        ).value,
        emailPassword: emailSettings.find(
          (email) => email.name === "email_password"
        ).value,
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.msg, { autoClose: 2000 });
        } else {
          toast.error(response.data.msg, { autoClose: 2000 });
        }
      })
      // .catch(error => console.error(error))
      .finally(() => {
        // setLoading(false); // Hide spinner
      });
  };

  // Function to load GTM dynamically and show toast notification
  const loadGTM = (gtmId) => {
    return new Promise((resolve, reject) => {
      if (!gtmId) {
        reject("GTM ID is required");
        return;
      }

      // Create GTM script
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      script.onload = () => {
        // Check if GTM loaded successfully by checking window.dataLayer
        if (window.dataLayer) {
          resolve("GTM loaded successfully");
        } else {
          reject("Failed to load GTM");
        }
      };
      script.onerror = () => {
        reject("Failed to load GTM script");
      };

      // Append script to the document
      document.head.appendChild(script);
    });
  };

  // Handle button click to load GTM and show notification
  const handleLoadGTM = (integrationSettings) => {
    console.log(integrationSettings);
    const GTM_ID = integrationSettings.find(
      (setting) => setting.name === "gtm_id"
    ).value; // Replace with your GTM ID
    console.log(GTM_ID);
    console.log(typeof GTM_ID);

    loadGTM(GTM_ID)
      .then((successMessage) => {
        toast.success(successMessage);
      })
      .catch((errorMessage) => {
        toast.error(errorMessage);
      });
  };
  const loadFacebookPixel = (pixelId) => {
    return new Promise((resolve, reject) => {
      if (!pixelId) {
        reject("Facebook Pixel ID is required");
        return;
      }

      // Check if Facebook Pixel is already initialized
      if (window.fbq) {
        resolve("Facebook Pixel is already loaded");
        return;
      }

      // Load Facebook Pixel
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      // Initialize the pixel with the given Pixel ID
      window.fbq("init", pixelId);
      window.fbq("track", "PageView");

      // Check if the Facebook Pixel tracking object exists
      setTimeout(() => {
        if (window.fbq) {
          resolve("Facebook Pixel loaded successfully");
        } else {
          reject("Failed to load Facebook Pixel");
        }
      }, 1000); // Give some time for the script to load
    });
  };
  const handleLoadFacebookPixel = (integrationSettings) => {
    const FACEBOOK_PIXEL_ID = integrationSettings.find(
      (setting) => setting.name === "facebook_pixel_id"
    ).value; // Replace with your Facebook Pixel ID
    loadFacebookPixel(FACEBOOK_PIXEL_ID)
      .then((successMessage) => {
        toast.success(successMessage);
      })
      .catch((errorMessage) => {
        toast.error(errorMessage);
      });
  };

  const handleUpdate = async (id, field, newValue) => {
    try {
      const response = await updateSetting(id, { [field]: newValue });
      // const response = await updateSetting(id, { value: newValue });
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id ? { ...setting, [field]: newValue } : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  const handleInlineUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, editorText);
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const handleCancelEdit = () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  const getEditor = (rowData, field) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {field === "long_value" ? (
        <InputTextarea
          autoFocus
          value={editorText}
          onChange={handleInputChange}
          style={{ width: "100%", resize: "both" }}
          rows={5}
        />
      ) : (
        <InputText autoFocus value={editorText} onChange={handleInputChange} />
      )}
      <Button
        icon="pi pi-check"
        className="p-button-rounded p-button-success p-mr-2 px-1"
        style={{ width: "max-content" }}
        onClick={() => handleInlineUpdate(rowData, field)}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger px-1"
        style={{ width: "max-content" }}
        onClick={handleCancelEdit}
      />
    </div>
  );

  const inlineEditor = (field, rowData) => (
    <Inplace
      closable={false}
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <span>
          {rowData[field] || <FaPlus style={{ cursor: "pointer" }} />}
        </span>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );

  const nameBody = (rowData) => <span>{snakeToPascal(rowData.name)}</span>;
  const testBody = (rowData) => {};
  const columns = [
    {
      field: "value",
      header: "Value",
      filter: true,
      body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
  ];
  const integrationColumns = [
    {
      field: "value",
      header: "Value",
      filter: true,
      body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
    {
      field: "long_value",
      header: "Long Value",
      filter: true,
      body: (rowData) => inlineEditor("long_value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
  ];

  const groupedSettings = {
    general: allSettings.filter((setting) => setting.type === "general"),
    course: allSettings.filter((setting) => setting.type === "course"),
    integration: allSettings.filter(
      (setting) => setting.type === "integration"
    ),
    others: allSettings.filter((setting) => setting.type === "others"),
  };

  return (
    <Can I="read" a="appSettings">
      <TabView className="container">
        <TabPanel header="General">
          <NewCustomListing
            allRecords={groupedSettings.general}
            heading={"General Settings"}
            firstColumn={
              <Column
                field="name"
                header="Name"
                sortable={true}
                style={{ borderBottom: "1px solid grey" }}
                body={(rowData) => nameBody(rowData)}
                filter={true}
                filterPlaceholder="Search by name"
                filterMatchMode="contains"
                headerStyle={{ width: "170px" }}
              />
            }
            columns={columns}
            isLoading={isLoading}
            handleRefresh={() =>
              setState((prevState) => ({
                ...prevState,
                refreshData: !prevState.refreshData,
              }))
            }
            editDataKey={"id"}
            tableRef={dt}
            timeStamps={true}
          />
        </TabPanel>
        <TabPanel header="Course">
          <NewCustomListing
            allRecords={groupedSettings.course}
            heading={"Course Settings"}
            firstColumn={
              <Column
                field="name"
                header="Name"
                sortable={true}
                style={{ borderBottom: "1px solid grey" }}
                body={(rowData) => nameBody(rowData)}
                filter={true}
                filterPlaceholder="Search by name"
                filterMatchMode="contains"
                headerStyle={{ width: "170px" }}
              />
            }
            columns={columns}
            isLoading={isLoading}
            handleRefresh={() =>
              setState((prevState) => ({
                ...prevState,
                refreshData: !prevState.refreshData,
              }))
            }
            editDataKey={"id"}
            tableRef={dt}
            timeStamps={true}
          />
        </TabPanel>
        <TabPanel header="Email Settings">
          <NewCustomListing
            allRecords={groupedSettings.integration}
            heading={"Email Settings"}
            // heading={"Integration Settings"}
            firstColumn={
              <Column
                field="name"
                header="Name"
                sortable={true}
                style={{ borderBottom: "1px solid grey" }}
                body={(rowData) => nameBody(rowData)}
                filter={true}
                filterPlaceholder="Search by name"
                filterMatchMode="contains"
                headerStyle={{ width: "170px" }}
              />
            }
            columns={columns}
            isLoading={isLoading}
            handleRefresh={() =>
              setState((prevState) => ({
                ...prevState,
                refreshData: !prevState.refreshData,
              }))
            }
            editDataKey={"id"}
            tableRef={dt}
            timeStamps={true}
          />
          <button
            className="button-primary-square px-2 py-2 mx-2 my-2"
            onClick={() => testEmailConnection(groupedSettings.integration)}
          >
            Test Connection
          </button>
        </TabPanel>
        <TabPanel header="Integration">
          <NewCustomListing
            allRecords={groupedSettings.others}
            heading={"Integration Settings"}
            firstColumn={
              <Column
                field="name"
                header="Name"
                sortable={true}
                style={{ borderBottom: "1px solid grey" }}
                body={(rowData) => nameBody(rowData)}
                filter={true}
                filterPlaceholder="Search by name"
                filterMatchMode="contains"
                headerStyle={{ width: "170px" }}
              />
            }
            columns={integrationColumns}
            isLoading={isLoading}
            handleRefresh={() =>
              setState((prevState) => ({
                ...prevState,
                refreshData: !prevState.refreshData,
              }))
            }
            editDataKey={"id"}
            tableRef={dt}
            timeStamps={true}
          />
          <button
            className="button-primary-square px-2 py-2 mx-2 my-2"
            onClick={() => handleLoadGTM(groupedSettings.others)}
          >
            Test GTM{" "}
          </button>
          <button
            className="button-secondary-square  px-2 py-2 mx-2 my-2"
            onClick={() => handleLoadFacebookPixel(groupedSettings.others)}
          >
            Test Facebook Pixel
          </button>
        </TabPanel>
      </TabView>
    </Can>
  );
};

export default AllSettings;

// import React, { useState, useEffect, useRef, useContext } from "react";
// // import { Can, AbilityContext } from "../configs/Ability-context.js";
// import { FaPlus } from "react-icons/fa";
// import { CustomListing } from "../Components/custom/CustomListing.jsx";
// import { Column } from "primereact/column";
// import { getSettings, updateSetting } from "../services/settings.js";
// import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
// import { InputText } from "primereact/inputtext";
// import { MDBPopper } from "mdbreact";
// // import PasswordConfirmationModal from "../misc/components/PasswordConfirmationModal";
// // import CustomSelect from "../components/custom/CustomSelect";
// import { InputSwitch } from "primereact/inputswitch";
// import { Button } from "primereact/button";
// import { snakeToPascal } from "../Components/custom/Helpers.js";
// const AllSettings = () => {
//   const dt = useRef(null);
//   const [
//     {
//       allSettings,
//       isLoading,
//       refreshData,
//       isUpdatingSetting,
//       selectedSetting,
//       activeEditor,
//       editorText,
//     },
//     setState,
//   ] = useState({
//     allSettings: [],
//     isLoading: false,

//     refreshData: false,
//     isUpdatingSetting: false,
//     selectedSetting: null,
//     activeEditor: { field: "", id: "" },
//     editorText: "",
//   });

//   const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
//   const [requiresPassword, setRequiresPassword] = useState(false);
//   const [pendingUpdate, setPendingUpdate] = useState({ id: null, value: "" });
//   // const ability = useContext(AbilityContext);
//   const settingForm = useRef(null);
//   let SettingTypes = allSettings.length ? ["app_setting", "admin_setting"] : [];

//   const userRole = localStorage.getItem("userRole");
//   useEffect(() => {
//     const fetchData = async () => {
//       setState((prevState) => ({ ...prevState, isLoading: true }));
//       try {
//         const { data } = await getSettings();
//         setState((prevState) => ({
//           ...prevState,
//           allSettings: data,
//           isLoading: false,
//         }));
//       } catch (error) {
//         console.log(error);
//         setState((prevState) => ({ ...prevState, isLoading: false }));
//       }
//     };
//     fetchData();
//   }, [refreshData]);

//   const handleUpdate = async (id, newValue) => {
//     try {
//       const response = await updateSetting(id, { value: newValue });
//       if (response.success) {
//         setState((prevState) => ({
//           ...prevState,
//           allSettings: prevState.allSettings.map((setting) =>
//             setting.id === id ? { ...setting, value: newValue } : setting
//           ),
//         }));
//         console.log(response.message);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleUpdateSettingType = async (id, setting_type) => {
//     try {
//       const response = await updateSetting(id, {
//         // value: newValue,
//         setting_type: setting_type,
//       });
//       console.log("setting_type", setting_type);
//       console.log(response);
//       if (response.success) {
//         setState((prevState) => ({
//           ...prevState,
//           allSettings: prevState.allSettings.map((setting) =>
//             setting.id === id
//               ? { ...setting, setting_type: setting_type }
//               : setting
//           ),
//         }));
//         console.log(response.message);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleUpdatePasswordProtection = async (id, passwordProtection) => {
//     try {
//       const response = await updateSetting(id, {
//         // value: newValue,
//         is_password_protected: passwordProtection,
//       });
//       console.log("passwordProtection", passwordProtection);
//       console.log(response);
//       if (response.success) {
//         setState((prevState) => ({
//           ...prevState,
//           allSettings: prevState.allSettings.map((setting) =>
//             setting.id === id
//               ? { ...setting, is_password_protected: passwordProtection }
//               : setting
//           ),
//         }));
//         console.log(response.message);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleInputChange = (e) => {
//     setState((prevState) => ({ ...prevState, editorText: e.target.value }));
//   };

//   const handleInlineUpdate = async (rowData) => {
//     // let form = settingForm.current;
//     // if (!form.checkValidity()) {
//     //   form.classList.add("was-validated");
//     //   return;
//     // }

//     // if (rowData.is_password_protected) {
//     //   setPendingUpdate({ id: rowData.id, value: editorText });
//     //   setIsConfirmingPassword(true);
//     // } else {
//     await handleUpdate(rowData.id, editorText);
//     setState((prevState) => ({
//       ...prevState,
//       activeEditor: { field: "", id: "" },
//       editorText: "",
//     }));
//     // }
//   };

//   const handlePasswordConfirm = (verified) => {
//     if (verified) {
//       handleUpdate(pendingUpdate.id, pendingUpdate.value);
//       setState((prevState) => ({
//         ...prevState,
//         activeEditor: { field: "", id: "" },
//         editorText: "",
//       }));
//     }
//     setIsConfirmingPassword(false);
//     setPendingUpdate({ id: null, value: "" });
//   };

//   const setEditor = (field, id, value) => () => {
//     setState((prevState) => ({
//       ...prevState,
//       activeEditor: { field, id },
//       editorText: value,
//     }));
//   };

//   const handleCancelEdit = () => {
//     setState((prevState) => ({
//       ...prevState,
//       activeEditor: { field: "", id: "" },
//       editorText: "",
//     }));
//   };

//   const getEditor = (rowData, field) => (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <InputText autoFocus value={editorText} onChange={handleInputChange} />
//       <Button
//         icon="pi pi-check"
//         className="p-button-rounded p-button-success p-mr-2 px-1"
//         style={{ width: "max-content" }}
//         onClick={() => handleInlineUpdate(rowData)}
//       />
//       <Button
//         icon="pi pi-times"
//         className="p-button-rounded p-button-danger px-1"
//         style={{ width: "max-content" }}
//         onClick={handleCancelEdit}
//       />
//     </div>
//   );

//   // const getEditor = (rowData, field) => (
//   //   <InputText
//   //     autoFocus
//   //     value={editorText}
//   //     onChange={handleInputChange}
//   //     onBlur={() => handleInlineUpdate(rowData)}
//   //   />
//   // );

//   const inlineEditor = (field, rowData) => (
//     <Inplace
//       closable={false}
//       active={activeEditor.field === field && activeEditor.id === rowData.id}
//       onOpen={setEditor(field, rowData.id, rowData[field])}
//     >
//       <InplaceDisplay>
//         <MDBPopper domElement tag="span" placement="right">
//           {rowData[field] ? (
//             <span>{rowData[field]}</span>
//           ) : (
//             <FaPlus style={{ cursor: "pointer" }} /> // Display Add Icon if value is empty
//           )}
//           <span>{rowData[field] ? "Click to edit" : "Add"}</span>
//         </MDBPopper>
//       </InplaceDisplay>
//       <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
//     </Inplace>
//   );

//   // const inlineEditor = (field, rowData) => (
//   //   <Inplace
//   //     closable={false}
//   //     // closable
//   //     active={activeEditor.field === field && activeEditor.id === rowData.id}
//   //     onOpen={setEditor(field, rowData.id, rowData[field])}
//   //   >
//   //     <InplaceDisplay>
//   //       <MDBPopper domElement tag="span" placement="right">
//   //         <span>{rowData[field]}</span>
//   //         <span>{"Click to edit"}</span>
//   //       </MDBPopper>
//   //     </InplaceDisplay>
//   //     <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
//   //   </Inplace>
//   // );
//   //   const SettingTypeBody = (rowData) => {
//   //     // let _seller = allSellers.find((s) => s.id === rowData.seller_id);
//   //     let settingType = rowData?.setting_type?.toUpperCase();
//   //     console.log("Setting Type:", settingType);
//   //     return (
//   //       ability.can("manage", "all") && (
//   //         <div className="custom-select-container" style={{}}>
//   //           <CustomSelect
//   //             options={SettingTypes}
//   //             value={rowData?.setting_type}
//   //             style={{
//   //               textTransform: "uppercase",
//   //             }}
//   //             placeholder="Select seller"
//   //             handleChange={(e) => {
//   //               handleUpdateSettingType(rowData.id, e.value);
//   //             }}
//   //           />
//   //         </div>
//   //       )
//   //     );
//   //   };
//   const PasswordProtectionBody = (rowData) => {
//     // const [checked, setChecked] = useState(false);

//     return (
//       // ability.can("manage", "all") &&
//       <div
//         className="card flex justify-content-center"
//         style={{ marginLeft: "170px" }}
//       >
//         <InputSwitch
//           checked={rowData.is_password_protected}
//           onChange={(e) => handleUpdatePasswordProtection(rowData.id, e.value)}
//         />
//       </div>
//     );
//   };
//   const nameBody = (rowData) => {
//     return (
//       // <div className="card flex justify-content-center">
//       <span>{snakeToPascal(rowData.name)}</span>
//       // </div>
//     );
//   };
//   const columns = [
//     {
//       field: "value",
//       header: "Value",
//       filter: true,
//       body: (rowData) => inlineEditor("value", rowData),
//       selectedByDefault: true,
//       filterPlaceholder: "Search by value",
//       filterMatchMode: "contains",
//       headerStyle: { width: "290px" },
//     },
//     {
//       field: "type",
//       header: "Type",
//       filter: true,
//       selectedByDefault: true,
//       filterPlaceholder: "Search by value",
//       filterMatchMode: "contains",
//       headerStyle: { width: "290px" },
//     },

//     // {
//     //   field: "setting_type",
//     //   header: "Setting Type",
//     //   //   body: SettingTypeBody,
//     //   sortable: true,
//     //   filter: true,
//     //   filterPlaceholder: "Search by Setting Type",
//     //   filterMatchMode: "contains",
//     //   selectedByDefault: true,
//     //   // filterElement: statusFilter,
//     // },
//     // {
//     //   field: "is_password_protected",
//     //   header: "Password Protection",
//     //   body: PasswordProtectionBody,
//     //   sortable: true,
//     //   filter: true,
//     //   filterPlaceholder: "Search by Setting Type",
//     //   filterMatchMode: "contains",
//     //   selectedByDefault: true,
//     //   // filterElement: statusFilter,
//     // },
//     // {
//     //   field: "setting_type",
//     //   header: "Setting Type",
//     //   sortable: true,
//     //   filter: true,
//     //   filterPlaceholder: "Search by Setting Type",
//     //   filterMatchMode: "contains",
//     //   selectedByDefault: true,
//     //   headerStyle: { width: "140px" },
//     // },
//   ];

//   return userRole === `"admin"` ? (
//     // <Can I="read" a="appSettings">
//     <form
//       ref={settingForm}
//       className="p-m-0 p-pt-4"
//       noValidate
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <CustomListing
//         allRecords={allSettings}
//         heading={"App settings"}
//         firstColumn={
//           <Column
//             field="name"
//             header="Name"
//             sortable={true}
//             body={(rowData) => nameBody(rowData)}
//             filter={true}
//             filterPlaceholder="Search by name"
//             filterMatchMode="contains"
//             headerStyle={{ width: "170px" }}
//           />
//         }
//         columns={columns}
//         permissionSubject={"appSettings"}
//         isLoading={isLoading}
//         handleRefresh={() =>
//           setState((prevState) => ({
//             ...prevState,
//             refreshData: !prevState.refreshData,
//           }))
//         }
//         editDataKey={"id"}
//         tableRef={dt}
//         timeStamps={true}
//       />
//     </form>
//   ) : (
//     /* <SettingModal
//         modalShow={isUpdatingSetting}
//         settingId={selectedSetting}
//         hideModal={() =>
//           setState((prevState) => ({
//             ...prevState,
//             isUpdatingSetting: false,
//             selectedSetting: null,
//           }))
//         }
//       /> */
//     /* <PasswordConfirmationModal
//         modalShow={isConfirmingPassword}
//         handleVerifyStatus={handlePasswordConfirm}
//         hideModal={() => setIsConfirmingPassword(false)}
//       /> */
//     // </Can>
//     <>
//       {/* <h2>{role}</h2>
//       <h3>{userRole}</h3> */}
//       <div>you are not allowed to see this page</div>
//     </>
//   );
// };

// export default AllSettings;
