import React, { useContext, useEffect, useState } from "react";
import "../styles/ThankYouPage.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { getSettings } from "../services/settings";
import { getOrder } from "../services/orders";
import { trackCustomEvent } from "../services/facebookPixel";
import { GlobalContext } from "../contexts/GlobalContext";

const ThankYouPage = () => {
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);

  const { allSettings } = useContext(GlobalContext);
  const { id } = useParams();
  // const [{ allSettings }, setState] = useState({
  //   allSettings: [],
  // });

  useEffect(() => {
    const storedResponse = localStorage.getItem("paymentResponse");
    if (storedResponse) {
      setPaymentResponse(JSON.parse(storedResponse));
      localStorage.removeItem("paymentResponse");
    }
  }, []);

  let paymentSuccessful;
  if (paymentResponse?.responseCode === "0") {
    paymentSuccessful = true;
  } else {
    paymentSuccessful = false;
  }
  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const data = await getOrder(id);
        setOrder(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };

    fetchOrder();
  }, [id]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setState((prevState) => ({ ...prevState, isLoading: true }));
  //     try {
  //       const { data } = await getSettings();
  //       setState((prevState) => ({
  //         ...prevState,
  //         allSettings: data,
  //         isLoading: false,
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //       setState((prevState) => ({ ...prevState, isLoading: false }));
  //     }
  //   };
  //   fetchData();
  // }, []);
  const email = allSettings.find((setting) => setting.name === "email")?.value;
  const phone = allSettings.find((setting) => setting.name === "phone")?.value;
  useEffect(() => {
    if (paymentResponse && order) {
      if (paymentSuccessful) {
        // Payment was successful
        trackCustomEvent("PaymentCompleted", {
          content_name: "Payment Completed",
          value: order?.amount_paid || 0, // Send the amount paid
          currency: "GBP",
          status: "Success",
          transaction_id: paymentResponse?.transactionUnique,
        });
      } else {
        // Payment failed
        trackCustomEvent("PaymentCompleted", {
          content_name: "Payment Failed",
          value: order?.amount_paid || 0, // Send the amount attempted
          currency: "GBP",
          status: "Failed",
        });
      }
    }
  }, [paymentResponse, order]);
  // }, [paymentResponse, paymentSuccessful, order]);
  return !loading ? (
    <div
      className="d-flex justify-content-center mt-5"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div></div>
      <p className="h5 font-semibold fs-3 fst-italic">
        Your Order Has Been Placed, Thank You{" "}
      </p>
      <p className="fs-3">Payment Info</p>
      <p className="fs-5">
        Status: {paymentResponse?.responseCode === "0" ? "Success" : "Failed"}
      </p>
      {paymentResponse?.responseCode === "0" && (
        <p className="fs-5">
          Transaction ID: {paymentResponse?.transactionUnique}
        </p>
      )}
      <div className="order-confirm-card order-confirm-container col-md-3">
        <div className="d-flex align-items-center justify-content-center mb-4">
          <p className="h5 font-semibold ">Your Order Details</p>
        </div>
        <div className="order-confirm-section">
          <div className="order-confirm-section mt-4">
            <div className="d-flex justify-content-between fs-5">
              <span>Your Order Number</span>
              <span className="text">{order?.id}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3 fs-5">
            <span>{order?.order_detail?.course?.hours} Hours Course</span>

            <span className="">£{order?.order_detail?.course?.price}</span>
          </div>
        </div>
        <div className="order-confirm-section mt-4">
          <div className="d-flex justify-content-between fs-5">
            <span>Total Order Amount</span>
            <span className="">£{order?.total}</span>
          </div>
        </div>
        <div className="order-confirm-section mt-4">
          <div className="d-flex justify-content-between fs-5">
            <span>Amount Recieved</span>
            <span className="">£{order?.amount_paid || 0}</span>
          </div>
        </div>
        <div className="order-confirm-section mt-4">
          <hr />
          <div className="d-flex justify-content-between fs-5">
            <span>Balance</span>
            <span className="font-weight-bold">
              £{order?.balance || order?.total}
            </span>
          </div>
          <br />
          {paymentSuccessful === false && (
            <div className="bg-warning p-3">
              <span className="text-danger fs-6">
                Payment attempt failed. Please contact on email{" "}
                <span
                  onClick={() => (window.location = `mailto:${email}`)}
                  className="text-black cursor-pointer"
                >
                  {email}
                </span>{" "}
                or directly call on{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => (window.location = `tel:${phone}`)}
                  className="text-black"
                >
                  {phone}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default ThankYouPage;
