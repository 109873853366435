import React, { useContext, useEffect, useRef, useState } from "react";
import { Badge } from "primereact/badge";
import { Menubar } from "primereact/menubar";
import "../styles/Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoLogoInstagram } from "react-icons/io5";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { IoLogoTiktok } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { getSettings } from "../services/settings";
import { getAllCourses } from "../services/courses";
import "primereact/resources/themes/saga-blue/theme.css"; // PrimeReact Theme
import "primereact/resources/primereact.min.css"; // PrimeReact CSS
import { GlobalContext } from "../contexts/GlobalContext";
import { trackCustomEvent } from "../services/facebookPixel";
import { MegaMenu } from "primereact/megamenu";

const Header = ({ handleLogOut, isLoggedIn }) => {
  const { allSettings, allCourses } = useContext(GlobalContext);
  // const [{ allSettings }, setState] = useState({
  //   allSettings: [],
  // });
  const userRole = localStorage.getItem("userRole");
  // const [allCourses, setAllCourses] = useState([]);
  const [showingContextMenu, setShowingContextMenu] = useState(false);
  // const ability = useContext(AbilityContext);
  const handleOutsideClick = (e) => {
    if (!e.target.closest(".context-menu") && !e.target.closest(".avatar")) {
      setShowingContextMenu(false);
    }
  };
  const [logoClickLink, setLogoClickLink] = useState("/");

  useEffect(() => {
    switch (JSON.parse(userRole)) {
      case "admin":
        setLogoClickLink("/admin");
        break;
      case "instructor":
        setLogoClickLink("/instructor");
        break;
      case "customer":
        setLogoClickLink("/student");
        break;
      default:
        setLogoClickLink("/");
        break;
    }
  }, [userRole]);
  useEffect(() => {
    if (showingContextMenu) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showingContextMenu]);

  const email = allSettings.find((setting) => setting.name === "email")?.value;
  const phone = allSettings.find((setting) => setting.name === "phone")?.value;
  const instagramLink = allSettings.find(
    (setting) => setting.name === "instagram_url"
  )?.value;
  const facebookLink = allSettings.find(
    (setting) => setting.name === "facebook_url"
  )?.value;
  const tiktokLink = allSettings.find(
    (setting) => setting.name === "tiktok_url"
  )?.value;
  const youtubeLink = allSettings.find(
    (setting) => setting.name === "youtube_url"
  )?.value;

  let navigate = useNavigate();
  let location = useLocation();
  const handleBookingInitiation = () => {
    trackCustomEvent("BookingInitiated", {
      content_name: "Booking Page",
      content_category: "Course Booking",
    });
  };

  const userId = localStorage.getItem("userId");

  const sortedCourses = allCourses.sort((a, b) => a.hours - b.hours);
  const profileUrl =
    JSON.parse(userRole) === "customer"
      ? "/student/profile"
      : "/instructor/profile";

  const categorizeCourses = (courses) => {
    return {
      Beginner: courses.filter((course) => course.category === "Beginner"),
      Intermediate: courses.filter(
        (course) => course.category === "Intermediate"
      ),
      Refresher: courses.filter((course) => course.category === "Refresher"),
    };
  };

  // Categorize the courses
  const categorizedCourses = categorizeCourses(sortedCourses);
  const CourseRecommendRenderer = (item) => (
    <Link to={item.link} onClick={() => {}}>
      <span className={item.icon} />
      <button
        className="mx-2 my-2 item-label btn btn-warning fw-bolder"
        style={{ color: "white" }}
      >
        {item.label}
      </button>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );
  const itemRenderer = (item) => (
    <Link
      to={item.link}
      className="flex align-items-center p-menuitem-link text-dark item-link "
    >
      <span className={item.icon} />
      <span className="mx-2 item-label text-white">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );
  const itemRendererforMobile = (item) => (
    <Link
      onClick={() => {
        navigate(item.link);
        window.location.href = item.link;
        console.log("itemLink", item.link);
      }}
      className="flex align-items-center p-menuitem-link text-dark"
    >
      <span className={item.icon} />
      <span className="mx-2 item-label">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );

  const courseItemRenderer = (item) => (
    <Link
      // to={`/course?courseId=${item.id}`}
      to={`/course/${item?.slug}`}
      style={{ textDecoration: "none" }}
    >
      <div className="courses-dropdown-div  d-md-flex justify-content-center">
        <span className="text-dark courses-dropdown border-4 ">
          {item.label}
          {/* {item.label} */}
        </span>
      </div>
    </Link>
  );
  const phoneRenderer = (item) => (
    <Link
      onClick={() => (window.location = `tel:${phone}`)}
      // onClick={() => (window.location = "tel:03335773131")}
      className="flex align-items-center p-menuitem-link text-dark"
    >
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );
  // const handleMouseEnter = () => {
  //   const megaMenuPanel = document.querySelector(".p-megamenu-panel");
  //   if (megaMenuPanel) {
  //     megaMenuPanel.style.display = "block"; // Ensure it is visible
  //     megaMenuPanel.style.zIndex = 1003; // Adjust z-index as needed
  //   }
  // };
  // // Mouse leave: hide the MegaMenu panel
  // const handleMouseLeave = () => {
  //   const megaMenuPanel = document.querySelector(".p-megamenu-panel");
  //   if (megaMenuPanel) {
  //     megaMenuPanel.style.display = "none"; // Hide the panel
  //     megaMenuPanel.style.zIndex = 0;
  //   }
  // };
  // const handleMouseEnter = (event) => {
  //   // Get the closest ancestor MegaMenu panel element
  //   const megaMenuPanel = event.currentTarget.closest(".p-megamenu-panel");

  //   // Check if the panel is currently hidden
  //   if (megaMenuPanel && megaMenuPanel.style.display === "none") {
  //     megaMenuPanel.style.display = "block"; // Show the panel
  //     megaMenuPanel.style.zIndex = 1003; // Adjust z-index as needed
  //   }
  // };

  // // Mouse leave: hide the panel only if not hovering over a child element
  // const handleMouseLeave = (event) => {
  //   const megaMenuPanel = event.currentTarget.closest(".p-megamenu-panel");

  //   if (megaMenuPanel) {
  //     const relatedTarget = event.relatedTarget;

  //     // Check if mouse is leaving to another child element within the panel
  //     if (!relatedTarget || !megaMenuPanel.contains(relatedTarget)) {
  //       megaMenuPanel.style.display = "none"; // Hide the panel
  //       megaMenuPanel.style.zIndex = 0;
  //     }
  //   }
  // };
  const megaMenuPanelRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [pEL, setPEL] = useState(0);
  const [bEl, setBEL] = useState(0);
  const [hasPositionSet, setHasPositionSet] = useState(false);

  const handleMouseEnter = (event) => {
    setIsHovering(true);
    if (!hasPositionSet) {
      const parentElement = event.currentTarget.getBoundingClientRect();
      if (parentElement) {
        setPEL(parentElement.left);
        setBEL(parentElement.bottom);
        setHasPositionSet(true);
      }
    }
    if (megaMenuPanelRef.current && hasPositionSet) {
      megaMenuPanelRef.current.style.display = "block"; // Show the panel
      megaMenuPanelRef.current.style.left = `${pEL}px`;
      megaMenuPanelRef.current.style.top = `${bEl}px`; // Display below the menu item

      megaMenuPanelRef.current.style.zIndex = 1003; // Adjust z-index if needed
    }
  };

  // Mouse Leave: Hide the MegaMenu panel
  const handleMouseLeave = (event) => {
    setIsHovering(false);
    const megaMenuPanel = megaMenuPanelRef.current;
    const relatedTarget = event.relatedTarget;

    // Check if mouse is moving outside of both 'Courses' and the MegaMenu panel
    if (!relatedTarget || !megaMenuPanel?.contains(relatedTarget)) {
      megaMenuPanel.style.display = "none"; // Hide the panel
      megaMenuPanel.style.zIndex = 0;
    }
  };
  const coursesRenderer = (item) => (
    <div
      className="flex align-items-center p-menuitem-link text-dark "
      onClick={() => {
        navigate("/courses");
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className={item.icon} />
      <span className="mx-2 item-label ">
        {item.label}{" "}
        <i
          className="pi pi-angle-down item-label"
          style={{ fontSize: "13px" }}
        />
      </span>
    </div>
  );
  const mailRenderer = (item) => (
    <Link
      onClick={() => (window.location = `mailto:${email}`)}
      // onClick={() => (window.location = "mailto:info@pass-swiftly.com")}
      className="flex align-items-center p-menuitem-link text-dark"
    >
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </Link>
  );
  const start = () => (
    // <div className=" border-1">
    <div className="d-flex flex-row">
      <Link to={logoClickLink}>
        <img
          className="logo"
          height="100"
          src={`${process.env.PUBLIC_URL}/Assets/logo.webp`}
          // src={require("../Assets/logo.png")}
          alt="logo"
          style={{ cursor: "pointer" }} // Adding pointer cursor to indicate clickable element
        />
      </Link>
      {/* <Link>
          <button className="button-tertiary d-md-none   mobile-header-booknow    ">
            Book Now
          </button>
        </Link> */}
    </div>
    // </div>
  );
  const end = () => (
    <div className="flex align-items-center gap-2 auth-buttons-container">
      {userId && (
        <Link onClick={handleLogOut}>
          <button
            className="btn btn-lg mx-2 auth-buttons rounded-pill"
            style={{ color: "orange" }}
          >
            <span>Log Out</span>
          </button>
        </Link>
      )}
      {!userId && (
        <Link to="/auth/login">
          <button
            className="btn btn-lg mx-2 auth-buttons"
            style={{ color: "orange" }}
          >
            Sign In
          </button>
        </Link>
      )}
      {location.pathname !== "/booking" && (
        <Link to="/booking" onClick={handleBookingInitiation}>
          <button
            className="btn btn-lg rounded-pill start-button"
            style={{ backgroundColor: "orange", color: "white" }}
          >
            Get Started
          </button>
        </Link>
      )}
    </div>
  );

  const courseItems = [
    {
      label: "Beginner Courses",
      items: categorizedCourses.Beginner.map((course) => ({
        label: `${course.hours} Hours Course`,
        slug: course.slug,
        id: course.id,
        template: courseItemRenderer, // Custom renderer
      })),
    },
    {
      label: "Intermediate Courses",
      items: categorizedCourses.Intermediate.map((course) => ({
        label: `${course.hours} Hours Course`,
        slug: course.slug,
        id: course.id,
        template: courseItemRenderer, // Custom renderer
      })),
    },
    {
      label: "Refresher Courses",
      items: categorizedCourses.Refresher.map((course) => ({
        label: `${course.hours} Hours Course`,
        slug: course.slug,
        id: course.id,
        template: courseItemRenderer, // Custom renderer
      })),
    },
  ];
  // const courseItems =
  //   sortedCourses.length > 0
  //     ? sortedCourses.map((course, index) => ({
  //         // label: `${course.hours} Hours ${course.category}`,
  //         label: `${course.hours} Hours Course`,
  //         slug: course.slug,
  //         // link: `/courses/course${index + 1}`,
  //         id: `${course.id}`,
  //         template: courseItemRenderer,
  //       }))
  //     : [];
  const itemsforMobile = [
    {
      // label: "03335773131",
      label: phone,
      icon: "pi pi-phone",
      template: phoneRenderer,
      className: "d-md-none", // Hide on desktop
    },
    {
      label: email,
      icon: "pi pi-envelope",
      template: mailRenderer,
      className: "d-md-none", // Hide on desktop
    },
    {
      label: "Try Course-Recommender",
      // icon: "pi pi-info-circle",
      className: "float-end",
      link: "/course-recommender",
      template: CourseRecommendRenderer,
    },
    {
      label: "Courses",
      // icon: "pi pi-book",
      items: courseItems,
      template: coursesRenderer,
      // link: "/courses/beginner",
      className: "course-heading-mobile",
    },
    {
      label: "Services",
      // icon: "pi pi-globe",
      link: "/services",
      template: itemRendererforMobile,
    },
    {
      label: "About",
      // icon: "pi pi-info-circle",
      link: "/about-us",
      template: itemRendererforMobile,
    },
    {
      label: "Blogs",
      link: "/blogs",
      template: itemRendererforMobile,
    },
    {
      label: "FAQs",
      // icon: "pi pi-question-circle",
      link: "/FAQs",
      template: itemRendererforMobile,
    },
    {
      template: () => <hr className="d-md-none" />,
    },
    !isLoggedIn && {
      label: "Get Started",
      // link: "/auth/login",
      icon: "pi pi-book",
      className: "context-menu-item",
      command: () => {
        navigate("/booking");
        handleBookingInitiation();
      },
    },
    !isLoggedIn && {
      label: "Sign In",
      // link: "/auth/login",
      icon: "pi pi-sign-in",
      className: "context-menu-item",
      command: () => navigate("/auth/login"),
    },
    isLoggedIn && {
      label: "Logout",
      className: "context-menu-item",
      icon: "pi pi-sign-out",
      command: () => handleLogOut(),
    },
    {
      template: () => (
        <div className="d-md-none mb-2">
          <span
            onClick={() => window.open("https://www.instagram.com", "_blank")}
          >
            <IoLogoInstagram
              className="mx-2"
              style={{ width: "21px", height: "21px" }}
            />
          </span>
          <span
            onClick={() => window.open("https://www.facebook.com", "_blank")}
          >
            <BiLogoFacebookCircle
              className="mx-2"
              style={{ width: "21px", height: "21px" }}
            />
          </span>
          <span onClick={() => window.open("https://www.tiktok.com", "_blank")}>
            <IoLogoTiktok
              className="mx-2"
              style={{ width: "21px", height: "21px" }}
            />
          </span>
          <span
            onClick={() => window.open("https://www.youtube.com", "_blank")}
          >
            <FaYoutube
              className="mx-2"
              style={{ width: "21px", height: "21px" }}
            />
          </span>
        </div>
      ),
    },
  ];
  const items = [
    {
      label: "Try Course-Recommender",
      // icon: "pi pi-info-circle",
      className: "float-end",
      link: "/course-recommender",
      template: CourseRecommendRenderer,
    },
    {
      label: "Courses",
      // icon: "pi pi-book",
      items: courseItems,
      template: coursesRenderer,
      // command: () => {
      //   navigate("/Courses");
      // },
      // link: "/courses/beginner",
    },
    {
      label: "Services",
      // icon: "pi pi-globe",
      link: "/services",
      className: "text-decoration-none",
      template: itemRenderer,
    },
    {
      label: "About",
      // icon: "pi pi-info-circle",
      link: "/about-us",
      template: itemRenderer,
    },
    {
      label: "FAQs",
      link: "/FAQs",
      template: itemRenderer,
    },
    {
      label: "Blogs",
      link: "/blogs",
      template: itemRenderer,
    },
  ];
  return (
    <div className="w-100">
      <div
        className="upper-header"
        style={{
          position: "static",
        }}
      >
        <div className="contacts background-primary">
          <div className="container-md">
            <ul className="nav justify-content-between align-items-center">
              <div className="m-2 contact-container">
                <li
                  onClick={() => (window.location = `tel:${phone}`)}
                  className="d-inline-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    <img
                      className="mx-2"
                      src={require("../Assets/Call-white.png")}
                      alt="phone icon"
                      width="17"
                      height="17"
                    />
                  </span>
                  <span className="mx-1 header-phone">
                    {phone ? phone : ""}
                  </span>
                </li>
                <li
                  onClick={() => (window.location = `mailto:${email}`)}
                  className="d-inline-flex align-items-center ml-3 header-email"
                  style={{ cursor: "pointer" }}
                >
                  <span className="mx-2">
                    <img
                      className="mx-2"
                      src={require("../Assets/Email-white.png")}
                      alt="email icon"
                      width="17"
                      height="17"
                    />
                  </span>

                  <span className="mx-1 header-email">
                    {email ? email : ""}
                  </span>
                </li>
              </div>
              <li className="nav-item">
                <span
                  onClick={() => window.open(instagramLink, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <IoLogoInstagram
                    color="white"
                    className="mx-2"
                    style={{ width: "17px", height: "17px" }}
                  />
                </span>
                <span
                  onClick={() => window.open(facebookLink, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <BiLogoFacebookCircle
                    className="mx-2"
                    color="white"
                    style={{ width: "17px", height: "17px" }}
                  />
                </span>
                <span
                  onClick={() => window.open(tiktokLink, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <IoLogoTiktok
                    className="mx-2"
                    color="white"
                    style={{ width: "17px", height: "17px" }}
                  />
                </span>
                <span
                  onClick={() => window.open(youtubeLink, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <FaYoutube
                    className="mx-2"
                    color="white"
                    style={{ width: "17px", height: "17px" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* <hr /> */}
        {/* <div className="menubar-mobile-outer-div border-2 "> */}
        <div className=" d-md-none px-1 py-1">
          <div className="d-flex flex-row align-items-center justify-content-between">
            {/* Menubar Button (Left) */}
            <div className="menubar-button-wrapper">
              <div className="menubar-button">
                <Menubar
                  style={{ backgroundColor: "transparent" }}
                  className="menu-button"
                  model={itemsforMobile}
                  button={{ className: "custom-menu-button", tabIndex: 0 }}
                />
              </div>
            </div>

            {/* Logo (Center) */}
            <div className="logo-container d-flex justify-content-center flex-grow-1">
              <Link to={logoClickLink}>
                <img
                  className="logo"
                  height="100"
                  src={`${process.env.PUBLIC_URL}/Assets/logo.webp`}
                  // src={require("../Assets/logo.webp")}
                  alt="logo"
                  // loading="lazy"
                  style={{ cursor: "pointer" }} // Adding pointer cursor to indicate clickable element
                />
              </Link>
            </div>

            {/* Book Now Button (Right) */}
            <div className="booknow-button d-md-none">
              <Link to="/booking" onClick={handleBookingInitiation}>
                <button className="button-tertiary mobile-header-booknow">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
          {/* <Menubar
            style={{ backgroundColor: "white", margin: "" }}
            className="d-md-none "
            // model={itemsforMobile}
            start={start}
            end={end}
            button={{ className: "custom-menu-button d-none", tabIndex: 0 }}
          />
          <Menubar
            style={{ backgroundColor: "transparent" }}
            // model={items}
            model={itemsforMobile}
            button={{ className: "custom-menu-button", tabIndex: 0 }}
          /> */}
        </div>
        <div className="big-screen-header">
          <div className="container">
            <Menubar
              style={{ backgroundColor: "white", margin: "20px" }}
              start={start}
              end={end}
              button={{ className: "custom-menu-button", tabIndex: 0 }}
            />
          </div>
          <div
            className="menu-container"
            style={{ backgroundColor: "#219EBC" }}
          >
            <MegaMenu
              className="header-megamenu"
              model={items}
              style={{ backgroundColor: "transparent" }}
              orientation="horizontal"
            />{" "}
            {isHovering && (
              <div
                className=" d-flex flex-row bg-white  "
                ref={megaMenuPanelRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  border: "1px solid rgb(238, 248, 253)",
                  borderTop: "none",
                  borderRadius: "5px",
                  display: "none",
                  position: "absolute",
                  zIndex: 1003,
                  marginTop: "-2px",
                  ...(!hasPositionSet && { top: "38%" }), // Conditionally apply 'top'
                }}
              >
                {/* Render sub-menu items here */}
                {courseItems.map((category, index) => (
                  <div
                    key={index}
                    className="d-flex justify-items-center align-items-center flex-column"
                  >
                    <h5 className="mx-3 mt-3 text-center">{category.label}</h5>
                    <ul className=" text-start px-1 py-0">
                      {category.items.map((course) => (
                        <Link
                          key={course.id}
                          // to={`/course?courseId=${item.id}`}
                          to={`/course/${course?.slug}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="courses-dropdown-div  d-md-flex justify-content-center">
                            <span className="text-dark courses-dropdown border-4 ">
                              {course.label}
                              {/* {item.label} */}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
            {/* <Menubar
              style={{ backgroundColor: "transparent" }}
              model={items}
              button={{ className: "custom-menu-button", tabIndex: 0 }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
