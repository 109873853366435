import React, { useContext, useEffect, useState } from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import { getSettings } from "../services/settings";
import { GlobalContext } from "../contexts/GlobalContext";
const Footer = () => {
  // const [{ allSettings }, setState] = useState({
  //   allSettings: [],
  //   isLoading: false,

  //   refreshData: false,
  //   isUpdatingSetting: false,
  //   selectedSetting: null,
  //   activeEditor: { field: "", id: "" },
  //   editorText: "",
  // });
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setState((prevState) => ({ ...prevState, isLoading: true }));
  //     try {
  //       const { data } = await getSettings();
  //       setState((prevState) => ({
  //         ...prevState,
  //         allSettings: data,
  //         isLoading: false,
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //       setState((prevState) => ({ ...prevState, isLoading: false }));
  //     }
  //   };

  //   fetchData();
  // }, []);
  const { allSettings } = useContext(GlobalContext);

  const email = allSettings.find((setting) => setting.name === "email")?.value;
  const phone = allSettings.find((setting) => setting.name === "phone")?.value;

  // const [whatsAppNumber, setWhatsAppNumber] = useState();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const { data } = await getSettings();
  //       const wn = data.find(
  //         (setting) => setting.name === "whatsapp_number"
  //       )?.value;
  //       setWhatsAppNumber(wn);
  //       console.log(whatsAppNumber);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, []);
  return (
    <div className="footer-outer-container  background-secondary ">
      <div className=" footer-container container-md">
        <footer className="row row-cols-5 py-5 my-5 border-top">
          <div className="footer-logo-container ">
            {" "}
            <a
              href="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              {" "}
              <img
                className="footer-logo"
                // src={`${process.env.PUBLIC_URL}/Assets/LogoFullWhite.webp`}
                src={require("../Assets/LogoFullWhite.webp")}
                height="80"
                alt="logo"
                loading="lazy"
              />
            </a>
          </div>
          <div className="col d-md-none"></div>
          <div className="col">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-white">
                  Home
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/about-us" className="nav-link p-0 text-white">
                  About
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/services" className="nav-link p-0 text-white">
                  Services
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/FAQs" className="nav-link p-0 text-white">
                  FAQs
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/terms-conditions" className="nav-link p-0 text-white">
                  Terms & Conditions
                </a>
              </li>
              <li className="nav-item mb-2 text-decoration-none text-white">
                <a
                  href={`tel:${phone}`}
                  className="text-decoration-none text-white"
                >
                  Phone
                </a>
              </li>
              {/* <a
              className=" text-decoration-none "
              href={`https://wa.me/${whatsAppNumber}`}
              // href="https://wa.me/+923054680277"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
              <span className="mx-2">WhatsApp Us</span>
            </a> */}
              {/* <li className="nav-item mb-2">
              <a href="/" className="nav-link p-0 text-white">
                More
              </a>
            </li> */}
            </ul>
          </div>
          <div className="col">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="/courses/beginner" className="nav-link p-0 text-white">
                  Courses
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/courses/beginner" className="nav-link p-0 text-white">
                  Pricing
                </a>
              </li>
              <li className="nav-item mb-2">
                <Link
                  to="/auth/instructor/sign-up"
                  className="nav-link p-0 text-white text-decoration-none"
                >
                  Join As Instructor
                </Link>
              </li>{" "}
              <li className="nav-item mb-2">
                <a href="/contact-us" className="nav-link p-0 text-white">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/privacy-policy" className="nav-link p-0 text-white">
                  PrivacyPolicy
                </a>
              </li>
              <li className="nav-item mb-2  text-decoration-none text-white">
                <a
                  href={`mailto:${email}`}
                  className="text-decoration-none text-white"
                >
                  Email
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-white">
                  {/* Home */}
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-white">
                  {/* Features */}
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-white">
                  {/* Pricing */}
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-white">
                  {/* FAQs */}
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="/" className="nav-link p-0 text-white">
                  {/* About */}
                </a>
              </li>
            </ul>
          </div>
        </footer>
        <hr />
        <small style={{ textAlign: "center" }}>
          <strong>
            Pass swiftly Ltd registered in Scotland company No Sc808067
            {/* 3 Loganswell Drive Glasgow G468QL */}
          </strong>
        </small>
      </div>
    </div>

    // <div className="row">
    //   <div className="col-3">
    //     <img
    //       className="mx-2 logo"
    //       src={require("../Assets/sport-car-PBKFF76 1.png")}
    //       alt="logo"
    //       // style={{ width: "286px", height: "97px" }}
    //       // width="286"
    //       // height="97"
    //     />
    //   </div>
    //   <div className="col-3 mt-5 ">
    //     <ul>
    //       <li>Home</li>
    //       <li>About</li>
    //       <li>FAQs</li>
    //       <li>More</li>
    //     </ul>
    //   </div>
    //   <div className="col-3 mt-5">
    //     <ul>
    //       <li>Our Services</li>
    //       <li>Our Packges</li>
    //       <li>Our Courses</li>
    //     </ul>
    //   </div>
    // </div>
  );
};

export default Footer;
