import React, { useState, useEffect, useRef, useContext } from "react";
// import { Can, AbilityContext } from "../configs/Ability-context.js";
import { FaPlus } from "react-icons/fa";
import { CustomListing } from "../Components/custom/CustomListing.jsx";
import { Column } from "primereact/column";
import { getSettings, updateSetting } from "../services/settings.js";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { MDBPopper } from "mdbreact";
// import PasswordConfirmationModal from "../misc/components/PasswordConfirmationModal";
// import CustomSelect from "../components/custom/CustomSelect";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../styles/AllAreas.css";
import { Checkbox } from "primereact/checkbox";
import { FloatLabel } from "primereact/floatlabel";
import { NewCustomListing } from "../Components/custom/NewCustomListing.jsx";
import { Can } from "../configs/Ability-context.js";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { getCoupons, updateCoupon } from "../services/coupons.js";
import { formatToCamelCase } from "../Components/custom/Helpers.js";
const AllCoupons = () => {
  const dt = useRef(null);
  const [loading, setLoading] = useState(false);
  const [
    {
      allCoupons,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
    },
    setState,
  ] = useState({
    allCoupons: [],
    isLoading: false,

    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });

  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState({ id: null, value: "" });
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  //   const [expiredAt,setExpiredAt]=useState('')
  const [visible, setVisible] = useState(false);
  const [couponType, setCouponType] = useState(null); // State for selected coupon type

  const couponTypes = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed", value: "fixed" },
  ];
  // const ability = useContext(AbilityContext);
  const settingForm = useRef(null);
  const userRole = localStorage.getItem("userRole");
  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getCoupons();
        setState((prevState) => ({
          ...prevState,
          allCoupons: data,
          isLoading: false,
        }));
      } catch (error) {
        console.log(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
  }, [refreshData]);

  const handleUpdate = async (id, field, newValue) => {
    try {
      //   const response = await updateCoupon(id, { value: newValue });
      const response = await updateCoupon(id, { [field]: newValue });
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allCoupons: prevState.allCoupons.map((coupon) =>
            coupon.id === id ? { ...coupon, [field]: newValue } : coupon
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateSettingType = async (id, setting_type) => {
    try {
      const response = await updateSetting(id, {
        // value: newValue,
        setting_type: setting_type,
      });
      console.log("setting_type", setting_type);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allAreas: prevState.allAreas.map((setting) =>
            setting.id === id
              ? { ...setting, setting_type: setting_type }
              : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCouponAdd = async (e) => {
    e.preventDefault();
    // console.log(postcode, areaName, isAllowed);
    const coupon = {
      type: couponType,
      value: value,
      code: code?.toUpperCase(),
      description: description,
      is_active: isActive,
    };
    console.log(coupon);

    // const area = {
    //   postcode: postcode,
    //   name: areaName,
    //   is_allowed: isAllowed,
    // };
    await fetch("/api/add-coupon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ coupon }),
    })
      .then((res) => {
        setVisible(false);
        setValue("");
        setCode("");
        setDescription(false);
        setState((prevState) => ({
          ...prevState,
          refreshData: !prevState.refreshData,
        }));
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleIsAllowedChange = async (id, isallowed) => {
    try {
      const response = await updateCoupon(id, {
        // value: newValue,
        is_active: isallowed,
      });
      //   console.log("isActive", isActive);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allCoupons: prevState.allCoupons.map((coupon) =>
            coupon.id === id ? { ...coupon, is_active: isallowed } : coupon
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  const handleInlineUpdate = async (rowData, field) => {
    // let form = settingForm.current;
    // if (!form.checkValidity()) {
    //   form.classList.add("was-validated");
    //   return;
    // }

    // if (rowData.is_password_protected) {
    //   setPendingUpdate({ id: rowData.id, value: editorText });
    //   setIsConfirmingPassword(true);
    // } else {
    await handleUpdate(rowData.id, field, editorText);
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
    // }
  };

  const handlePasswordConfirm = (verified) => {
    if (verified) {
      handleUpdate(pendingUpdate.id, pendingUpdate.value);
      setState((prevState) => ({
        ...prevState,
        activeEditor: { field: "", id: "" },
        editorText: "",
      }));
    }
    setIsConfirmingPassword(false);
    setPendingUpdate({ id: null, value: "" });
  };

  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const handleCancelEdit = () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  const getEditor = (rowData, field) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputText autoFocus value={editorText} onChange={handleInputChange} />
      <Button
        icon="pi pi-check"
        className="p-button-rounded p-button-success p-mr-2"
        style={{ width: "24px" }}
        onClick={() => handleInlineUpdate(rowData, field)}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-danger"
        style={{ width: "24px" }}
        onClick={handleCancelEdit}
      />
    </div>
  );

  // const getEditor = (rowData, field) => (
  //   <InputText
  //     autoFocus
  //     value={editorText}
  //     onChange={handleInputChange}
  //     onBlur={() => handleInlineUpdate(rowData)}
  //   />
  // );

  const inlineEditor = (field, rowData) => (
    <Inplace
      closable={false}
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <MDBPopper domElement tag="span" placement="top">
          {rowData[field] ? (
            <span>{rowData[field]}</span>
          ) : (
            <span>
              <FaPlus style={{ cursor: "pointer" }} />
            </span>
          )}
          <span>{rowData[field] ? "Click to edit" : "Click to add"}</span>
        </MDBPopper>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );

  // const inlineEditor = (field, rowData) => (
  //   <Inplace
  //     closable={false}
  //     // closable
  //     active={activeEditor.field === field && activeEditor.id === rowData.id}
  //     onOpen={setEditor(field, rowData.id, rowData[field])}
  //   >
  //     <InplaceDisplay>
  //       <MDBPopper domElement tag="span" placement="right">
  //         <span>{rowData[field]}</span>
  //         <span>{"Click to edit"}</span>
  //       </MDBPopper>
  //     </InplaceDisplay>
  //     <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
  //   </Inplace>
  // );
  //   const SettingTypeBody = (rowData) => {
  //     // let _seller = allSellers.find((s) => s.id === rowData.seller_id);
  //     let settingType = rowData?.setting_type?.toUpperCase();
  //     console.log("Setting Type:", settingType);
  //     return (
  //       ability.can("manage", "all") && (
  //         <div className="custom-select-container" style={{}}>
  //           <CustomSelect
  //             options={SettingTypes}
  //             value={rowData?.setting_type}
  //             style={{
  //               textTransform: "uppercase",
  //             }}
  //             placeholder="Select seller"
  //             handleChange={(e) => {
  //               handleUpdateSettingType(rowData.id, e.value);
  //             }}
  //           />
  //         </div>
  //       )
  //     );
  //   };
  const isAllowedBody = (rowData) => {
    // const [checked, setChecked] = useState(false);

    return (
      // ability.can("manage", "all") &&
      //   <div
      //     className="flex switch-toggle justify-content-center"
      //     // style={{ marginLeft: "170px" }}
      //   >
      <InputSwitch
        // style={{ maxWidth: "2px" }}
        checked={rowData.is_active}
        onChange={(e) => handleIsAllowedChange(rowData.id, e.value)}
      />
      //   </div>
    );
  };
  const columns = [
    {
      field: "value",
      header: "Value",
      filter: true,
      body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      //   headerStyle: { width: "290px" },
    },
    {
      field: "type",
      header: "Type",
      filter: true,
      body: (rowData) => {
        return <div>{formatToCamelCase(rowData.type)}</div>;
      },
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      //   headerStyle: { width: "290px" },
    },
    {
      field: "description",
      header: "Description",
      filter: true,
      body: (rowData) => inlineEditor("description", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      //   headerStyle: { width: "290px" },
    },
    {
      field: "useage_count",
      header: "Useage Count",
      filter: true,
      // body: (rowData) => inlineEditor("description", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      //   headerStyle: { width: "290px" },
    },
    {
      field: "is_acitve",
      header: "Active",
      body: isAllowedBody,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Setting Type",
      filterMatchMode: "contains",
      selectedByDefault: true,
      // filterElement: statusFilter,
    },
  ];

  return userRole === `"admin"` ? (
    <Can I="manage" a="all">
      <form
        ref={settingForm}
        className="p-m-0 p-pt-4"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        {/* <CustomListing */}
        <NewCustomListing
          allRecords={allCoupons}
          heading={"Coupons"}
          firstColumn={
            <Column
              field="code"
              header="Coupon Code"
              sortable={true}
              filter={true}
              style={{ borderBottom: "1px solid grey" }}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          // permissionSubject={"appSettings"}
          isLoading={isLoading}
          // handleAdd={handleAreaAdd}
          handleAdd={() => {
            setVisible(true);
          }}
          // handleRefresh={() =>
          //   setState((prevState) => ({
          //     ...prevState,
          //     refreshData: !prevState.refreshData,
          //   }))
          // }
          editDataKey={"id"}
          tableRef={dt}
          timeStamps={true}
        />
      </form>
      <div className="d-flex justify-content-start align-items-center container">
        {/* <span>Add another area</span>
        <FaPlus
          style={{ cursor: "pointer", width: "20px", height: "20px" }}
          // onClick={handleAreaAdd}
          onClick={() => setVisible(true)}
        /> */}
        <Dialog
          header="Add New Coupon"
          visible={visible}
          style={{ width: "fit-content" }}
          // style={{ width: "50vw" }}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          <form onSubmit={handleCouponAdd} className="">
            <div className="dialog-field-div mt-4">
              <FloatLabel>
                <InputText
                  type="text"
                  className="dialog-input"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  placeholder="Enter Coupon Value"
                  required
                />
                <label>Value</label>
              </FloatLabel>
            </div>
            {/* <br /> */}
            {/* <hr /> */}
            <div className="dialog-field-div">
              <FloatLabel>
                <InputText
                  type="text"
                  value={code}
                  className="dialog-input"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
                <label>Code</label>
              </FloatLabel>
            </div>
            <div className="dialog-field-div">
              <FloatLabel>
                <InputTextarea
                  type="text"
                  value={description}
                  className="dialog-input"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <label>Description</label>
              </FloatLabel>
            </div>
            <div className="dialog-field-div">
              <FloatLabel>
                <Dropdown
                  id="couponType"
                  value={couponType}
                  options={couponTypes}
                  onChange={(e) => setCouponType(e.value)}
                  placeholder="Select Coupon Type"
                  className="dialog-input"
                  required
                />
                <label htmlFor="couponType">Coupon Type</label>
              </FloatLabel>
            </div>
            {/* <hr /> */}
            <div className="dialog-field-div">
              <label htmlFor="isAllowed">Active:</label>
              <Checkbox
                type="checkbox"
                className="dialog-checkbox"
                id="isAllowed"
                value={isActive}
                checked={isActive === true}
                onChange={(e) => {
                  setIsActive(!isActive);
                  // setIsAllowed(isAllowed);
                }}
              />
            </div>
            <button type="submit" className="btn dialog-button">
              Add Area
            </button>
          </form>
          {/* <p className="m-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p> */}
        </Dialog>
      </div>
    </Can>
  ) : (
    /* <SettingModal
        modalShow={isUpdatingSetting}
        settingId={selectedSetting}
        hideModal={() =>
          setState((prevState) => ({
            ...prevState,
            isUpdatingSetting: false,
            selectedSetting: null,
          }))
        }
      /> */
    /* <PasswordConfirmationModal
        modalShow={isConfirmingPassword}
        handleVerifyStatus={handlePasswordConfirm}
        hideModal={() => setIsConfirmingPassword(false)}
      /> */
    // </Can>
    <>
      {/* <h2>{role}</h2>
      <h3>{userRole}</h3> */}
      <div>you are not allowed to see this page</div>
    </>
  );
};

export default AllCoupons;
