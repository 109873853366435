import React from "react";
import "../styles/AboutUs.css"; // This is where you can add custom styles
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Helmet>
        <title>About Us | Pass Swiftly </title>
        {/* <title>About  | Trusted Driving School in the UK </title> */}
        <meta
          name="description"
          content="Learn more about Pass Swiftly, a UK driving school dedicated to quality driving education. Our mission is to help you become a safe, confident driver with top-rated instructors."
        />
      </Helmet>
      <div className="about-us-content">
        <h1>About Us</h1>
        <p>
          Pass Swiftly Ltd is dedicated to providing top-notch driving
          instruction to learners in Glasgow and surrounding areas, with plans
          to expand across the UK. Our mission is to empower students of all
          levels with the skills and confidence needed to become safe and
          competent drivers. Whether you're a beginner, an intermediate learner,
          or someone looking to refresh your driving skills, we have the right
          course for you. We pride ourselves on our professional instructors,
          modern teaching methods, and a supportive learning environment. At
          Pass Swiftly Ltd, we're committed to helping you pass swiftly and
          safely.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
