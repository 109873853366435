import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "primereact/card"; // PrimeReact Card Component
import { Button } from "primereact/button"; // PrimeReact Button
import { Image } from "primereact/image"; // PrimeReact Image
import { formatDate } from "../Components/Helpers/Helper";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blog = () => {
  // useEffect(() => {
  //   let response;
  //   response = axios.get(
  //     "https://www.pass-swiftly.com/content/wp-json/wp/v2/posts"
  //   );
  //   console.log(response);
  // }, []);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let response;
        // response = await axios.get(
        //   "https://www.pass-swiftly.com/content/wp-json/wp/v2/posts"
        // );
        response = await axios.get("/api/wp");
        console.log(response);
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching the posts:", error);
      }
    };

    fetchPosts();
  }, []);
  return (
    <div className="container">
      <Helmet>
        <title>Our Blog | Driving Tips & Licence Guides | Pass Swiftly</title>
        {/* <title>Driving Tips & Licence Guides | Pass Swiftly Blog</title> */}
        <meta
          name="description"
          content="Read our blog for expert driving tips, licence advice, and the latest updates on UK driving regulations. Stay informed and improve your driving skills with Pass Swiftly!"
        />
      </Helmet>
      <h1 className="my-4 text-center">Blog Posts</h1>
      <div className="row">
        {posts.length > 0 ? (
          posts.map((post) => (
            <div
              key={post.id}
              className="col-md-6 mb-4 cursor-pointer"
              // onClick={() => (window.location.href = `/blogs/${post.slug}`)}
            >
              <Link
                to={`/blogs/${post.slug}`}
                state={{
                  title: post.seo_title,
                  description: post.seo_description,
                }}
                className="text-decoration-none"
              >
                <Card
                  title={
                    <div
                      className="text-center"
                      dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                    ></div>
                  }
                >
                  <Image
                    src={
                      post.fimg_url ||
                      require("../Assets/Hero-Section-Car.webp")
                    }
                    // src={
                    //   post.featured_media_url ||
                    //   "https://primefaces.org/cdn/primereact/images/galleria/galleria12.jpg" ||
                    //   "https://via.placeholder.com/300"
                    // } // Placeholder or actual image URL
                    alt="Post Thumbnail"
                    width="100%"
                    className="img-fluid d-flex justify-content-center align-items-center" // Bootstrap class for responsive image
                  />
                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <Button
                      label="Read More"
                      className="p-button-sm start-0"
                      // onClick={() =>
                      //   (window.location.href = `/post/${post.slug}`)
                      // }
                    />
                    <span
                      className="p-button-sm mx-5"
                      style={{ marginLeft: "auto" }}
                    >
                      <i className="pi pi-calendar mx-1" />
                      {formatDate(post.date)}
                    </span>
                  </div>
                </Card>
              </Link>
            </div>
          ))
        ) : (
          <p className="text-center mt-5">
            {" "}
            <i className="pi pi-spin pi-spinner fs-1" />
          </p>
          /* <p>Loading posts...</p> */
        )}
      </div>
    </div>
  );
};

export default Blog;
