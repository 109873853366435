import React, { useEffect, useState } from "react";
import { getAllCourses } from "../services/courses";
import "../styles/CourseRecommender.css";
import { FaBook, FaCalendarCheck, FaCar, FaTools } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import { ProgressBar } from "primereact/progressbar";
import { FiRefreshCcw } from "react-icons/fi";
import { IoRefresh } from "react-icons/io5";
import { Helmet } from "react-helmet";

const DrivingCourseRecommender = () => {
  const [ageGroup, setAgeGroup] = useState("");
  const [transmission, setTransmission] = useState("manual");
  const [wantTheoryTest, setWantTheoryTest] = useState(false);
  const [wantPracticalTest, setWantPracticalTest] = useState(false);
  const [drivingSkills, setDrivingSkills] = useState("");
  const [hasDrivenBefore, setHasDrivenBefore] = useState(null);
  const [steeringControl, setSteeringControl] = useState("");
  const [canDriveStraight, setCanDriveStraight] = useState(null);
  const [canShiftGears, setCanShiftGears] = useState(null);
  const [testReady, setTestReady] = useState(null);
  const [recommendation, setRecommendation] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [nextpressed, setNextPressed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showCrunchingNumbers, setShowCrunchingNumbers] = useState(false);
  const [showRecommmendationButton, setShowRecommendationButton] =
    useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const linkedMessage = location.state?.message;
  useEffect(() => {
    const getCourses = async () => {
      await getAllCourses()
        .then((data) => {
          setAllCourses(data);
          console.log(allCourses);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getCourses();
  }, []);
  useEffect(() => {
    if (progress === 100) {
      setShowCrunchingNumbers(true);
      setInterval(() => {
        setShowCrunchingNumbers(false);
      }, 3000);
      setTimeout(() => {
        determineRecommendation();
      }, 3000);
    }
  }, [progress]);
  // useEffect(() => {
  //   const updateProgress = () => {
  //     let progressValue = 0;

  //     if (ageGroup) progressValue += 20;
  //     if (nextpressed) progressValue += 20;
  //     // if (transmission) progressValue += 10;
  //     if (drivingSkills) progressValue += 20;
  //     if (hasDrivenBefore !== null) progressValue += 10;
  //     if (canDriveStraight !== null) progressValue += 10;
  //     if (canShiftGears !== null) progressValue += 10;
  //     if (steeringControl) progressValue += 10;
  //     // if (wantTheoryTest || wantPracticalTest) progressValue += 10;

  //     setProgress(progressValue);
  //   };
  //   updateProgress();
  // }, [
  //   ageGroup,
  //   nextpressed,
  //   // transmission,
  //   // wantTheoryTest,
  //   // wantPracticalTest,
  //   drivingSkills,
  //   hasDrivenBefore,
  //   canDriveStraight,
  //   canShiftGears,
  //   steeringControl,
  // ]);
  // const handleStartAgain = () => {
  //   setAgeGroup("");
  //   setTransmission("manual");
  //   setWantTheoryTest(false);
  //   setWantPracticalTest(false);
  //   setDrivingSkills("");
  //   setHasDrivenBefore(null);
  //   setSteeringControl("");
  //   setCanDriveStraight(null);
  //   setCanShiftGears(null);
  //   setTestReady(null);
  //   setRecommendation("");
  //   setAllCourses([]);
  //   setNextPressed(false);
  //   setProgress(0);
  //   setShowCrunchingNumbers(false);
  //   setShowRecommendationButton(false);
  // };
  const handleChangeAnswer = (question, newValue) => {
    switch (question) {
      case "ageGroup":
        setAgeGroup(newValue);
        break;
      case "transmission":
        setTransmission(newValue);
        break;
      case "drivingSkills":
        setDrivingSkills(newValue);
        break;
      case "hasDrivenBefore":
        setHasDrivenBefore(newValue);
        break;
      case "canDriveStraight":
        setCanDriveStraight(newValue);
        break;
      case "canShiftGears":
        setCanShiftGears(newValue);
        break;
      case "steeringControl":
        setSteeringControl(newValue);
        break;
      default:
        break;
    }
  };
  const saveTransmissionAndTest = () => {
    sessionStorage.setItem("transmission", JSON.stringify(transmission));
    sessionStorage.setItem("wantTheoryTest", JSON.stringify(wantTheoryTest));
    sessionStorage.setItem(
      "wantPracticalTest",
      JSON.stringify(wantPracticalTest)
    );
  };
  const determineRecommendation = () => {
    let recommendedCourse = "";

    if (hasDrivenBefore === false || canDriveStraight === false) {
      recommendedCourse = allCourses.find(
        (course) => course.hours === "48" && course.category === "Beginner"
      );
      // setShowRecommendationButton(true);
    } else if (canDriveStraight === true) {
      recommendedCourse = allCourses.find(
        (course) => course.hours === "40" && course.category === "Beginner"
      );
      // setShowRecommendationButton(true);
    } else if (steeringControl === "Average" || canShiftGears === false) {
      recommendedCourse = allCourses.find(
        (course) => course.hours === "30" && course.category === "Intermediate"
      );
      // setShowRecommendationButton(true);
    } else if (drivingSkills === "Ready For Test") {
      saveTransmissionAndTest();
      navigate("/courses?courseType=Refresher");
      // recommendedCourse = allCourses.find(
      //   (course) => course.hours === "10" && course.category === "Refresher"
      // );
      // setShowRecommendationButton(true);
    } else {
      recommendedCourse = allCourses.find(
        (course) => course.hours === "25" && course.category === "Intermediate"
      );
      // setShowRecommendationButton(true);
    }

    setRecommendation(recommendedCourse);
  };
  //   if (hasDrivenBefore === false || canDriveStraight === false) {
  //     setRecommendation("Basic Course - 48 Hours");
  //     // } else if (steeringControl === "Needs Work" || canDriveStraight === true) {
  //   } else if (canDriveStraight === true) {
  //     setRecommendation("Basic Course - 40 Hours");
  //   } else if (steeringControl === "Average" || canShiftGears === false) {
  //     setRecommendation("Intermediate Course - 30 Hours");
  //     // } else if (testReady === true) {
  //   } else if (drivingSkills === "Ready For Test") {
  //     setRecommendation("Refresher Course - 10 Hours");
  //   } else {
  //     setRecommendation("Intermediate Course - 25 Hours");
  //   }
  // };
  const isFormComplete = () => {
    // Check if the age group is selected
    if (!ageGroup) return false;

    // Check if the driving skills question is answered
    if (!drivingSkills) return false;

    // If driving skills are "Just Starting", ensure hasDrivenBefore is answered
    if (drivingSkills === "Just Starting" && hasDrivenBefore === null)
      return false;

    // If driving skills are answered and the user has driven before is answered
    if (drivingSkills === "Know The basics" && canShiftGears === null)
      return false;

    // If the user has driven before and the ability to drive straight is answered
    if (hasDrivenBefore === true && canDriveStraight === null) return false;

    // If the driving skills are "Know The basics", ensure canShiftGears is answered
    if (drivingSkills === "Know The basics" && canShiftGears === null)
      return false;

    // If driving skills are "Know The basics" and steeringControl is not set
    if (
      drivingSkills === "Know The basics" &&
      canShiftGears !== null &&
      !steeringControl
    )
      return false;

    // If the user is at a point where no further steps are needed
    return true;
  };
  return (
    <div className="recommender-container mt-2 mb-1">
      <Helmet>
        <title>
          Find the Best Driving Course for You | Course Recommender | Pass
          Swiftly
        </title>
        <meta
          name="description"
          content="Not sure where to start? Use our Course Recommender to find the perfect driving lessons tailored to your experience level and goals. Start your journey with Pass Swiftly."
        />
      </Helmet>
      <h1 className="text-center fs-1">
        <FaCar /> Course Recommender
      </h1>
      <div className="recommender-inner-container">
        <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
          <ProgressBar
            value={progress}
            displayValueTemplate={() => null}
            style={{ height: "10px", width: "60%" }}
          />
        </div>
        {/* {ageGroup && (
          <div
            onClick={handleStartAgain}
            className="fs-6 fw-bold cursor-pointer text-end"
          >
            Start Again <IoRefresh />
          </div>
        )} */}
        {ageGroup === "" && (
          <div>
            <h2>Your Age Group</h2>
            <div className="d-flex flex-column">
              <button
                className="CRbutton"
                onClick={() => {
                  setAgeGroup("16-19");
                  setProgress(20);
                }}
              >
                16-19{" "}
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setAgeGroup("20-30");
                  setProgress(20);
                }}
              >
                20-30{" "}
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setAgeGroup("31-45");
                  setProgress(20);
                }}
              >
                31-45
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setAgeGroup("46-55");
                  setProgress(20);
                }}
              >
                46-55
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setAgeGroup("56 Above");
                  setProgress(20);
                }}
              >
                56 Above
              </button>
            </div>
          </div>
        )}
        {ageGroup && !nextpressed && (
          <div className="p-field-radiobutton ">
            <div className="d-flex justify-content-center">
              <strong className="mb-5 mx-3">Pick a gearbox </strong>
              <br />
              <RadioButton
                inputId="manual"
                name="transmission"
                value="manual"
                onChange={(e) => {
                  setTransmission(e.target.value);
                }}
                checked={transmission === "manual"}
              />
              <label htmlFor="manual" className="mx-2">
                Manual
              </label>
              <RadioButton
                inputId="automatic"
                name="transmission"
                value="automatic"
                onChange={(e) => {
                  setTransmission(e.target.value);
                }}
                checked={transmission === "automatic"}
              />
              <label htmlFor="automatic" className="mx-2">
                Automatic
              </label>
            </div>
            <div className="d-flex justify-content-center flex-column align-items-center">
              {/* <div> */}
              <p className="side-title">Add Theory Test?</p>
              <InputSwitch
                // style={{ marginLeft: "30px" }}
                checked={wantTheoryTest}
                onChange={(e) => setWantTheoryTest(e.value)}
              />
              {/* </div> */}
              {/* <div style={{}}> */}
              <p className="side-title">Add Practical Test?</p>
              <InputSwitch
                // style={{ marginLeft: "30px" }}
                checked={wantPracticalTest}
                onChange={(e) => setWantPracticalTest(e.value)}
              />
              {/* </div> */}
            </div>
            <div className=" d-flex justify-content-end mt-5 ">
              <button
                className="CRbutton-next"
                onClick={() => {
                  setNextPressed(true);
                  setProgress((prev) => prev + 20);
                  // setProgress(40);
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {nextpressed && drivingSkills === "" && (
          <div className="questions col">
            <h2>
              {" "}
              <FaBook />
              How About Your Driving Skills
            </h2>
            <div className="d-flex flex-column">
              <button
                className="CRbutton"
                onClick={() => {
                  setDrivingSkills("Just Starting");
                  setProgress((prev) => prev + 20);
                }}
              >
                Just starting
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setDrivingSkills("Know The basics");
                  setProgress((prev) => prev + 20);
                }}
              >
                I know the basics
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setDrivingSkills("Ready For Test");
                  setProgress((prev) => prev + 60);
                }}
              >
                Ready for Test
              </button>
            </div>
          </div>
        )}
        {drivingSkills === "Just Starting" && hasDrivenBefore === null && (
          <div>
            <h2>
              {" "}
              <FaTools /> Have you ever driven before?
            </h2>
            <div className="d-flex flex-column">
              <button
                className="CRbutton"
                onClick={() => {
                  setHasDrivenBefore(true);
                  setProgress((prev) => prev + 20);
                }}
              >
                Yes
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setHasDrivenBefore(false);
                  setProgress((prev) => prev + 40);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}

        {hasDrivenBefore === true && canDriveStraight === null && (
          <div>
            <h2>
              {" "}
              <FaTools /> Can you drive on a straight road?
            </h2>
            <div className="d-flex flex-column">
              <button
                className="CRbutton"
                onClick={() => {
                  setCanDriveStraight(true);
                  setProgress((prev) => prev + 20);
                }}
              >
                Yes
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setCanDriveStraight(false);
                  setProgress((prev) => prev + 20);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
        {/* {hasDrivenBefore === true && !steeringControl && (
        <div>
          <h2>How is your steering wheel control?</h2>
          <button className="CRbutton" onClick={() => setSteeringControl("Good")}>Good</button>
          <button className="CRbutton" onClick={() => setSteeringControl("Average")}>Average</button>
          <button className="CRbutton" onClick={() => setSteeringControl("Needs Work")}>
            Needs Work
          </button>
        </div>
      )} */}

        {/* {steeringControl && canDriveStraight === null && (
        <div>
          <h2>Can you drive on a straight road?</h2>
          <button className="CRbutton" onClick={() => setCanDriveStraight(true)}>Yes</button>
          <button className="CRbutton" onClick={() => setCanDriveStraight(false)}>No</button>
        </div>
      )} */}

        {/* {canDriveStraight !== null && canShiftGears === null && ( */}
        {drivingSkills === "Know The basics" && canShiftGears === null && (
          <div>
            <h2>Can you shift gears up and down?</h2>
            <div className="d-flex flex-column">
              <button
                className="CRbutton"
                onClick={() => {
                  setCanShiftGears(true);
                  setProgress((prev) => prev + 20);
                }}
              >
                Yes
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setCanShiftGears(false);
                  setProgress((prev) => prev + 20);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
        {canShiftGears !== null && !steeringControl && (
          <div>
            <h2>
              {" "}
              <FaTools /> How is your steering wheel control?
            </h2>
            <div className="d-flex flex-column">
              <button
                className="CRbutton"
                onClick={() => {
                  setSteeringControl("Good");
                  setProgress((prev) => prev + 20);
                }}
              >
                Good
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setSteeringControl("Average");
                  setProgress((prev) => prev + 20);
                }}
              >
                Average
              </button>
              <button
                className="CRbutton"
                onClick={() => {
                  setSteeringControl("Needs Work");
                  setProgress((prev) => prev + 20);
                }}
              >
                Needs Work
              </button>
            </div>
          </div>
        )}
        {/* {canShiftGears !== null && testReady === null && (
        <div>
          <h2>Are you ready for the test?</h2>
          <button className="CRbutton" onClick={() => setTestReady(true)}>Yes</button>
          <button className="CRbutton" onClick={() => setTestReady(false)}>No</button>
        </div>
      )} */}

        {/* {isFormComplete() && !recommendation && (
          <button
            className="recommend-button CRbutton"
            onClick={determineRecommendation}
          >
            Get Recommendation
          </button>
        )} */}
        {recommendation && !showCrunchingNumbers && (
          <div className="recommendation">
            <h2>
              <FaCalendarCheck /> Recommended Course:
            </h2>
            {/* <p>
            <strong>Name:</strong> {recommendation.name || "N/A"}
          </p> */}
            <p>
              <strong>Hours:</strong> {recommendation.hours}
            </p>
            <p>
              <strong>Category:</strong> {recommendation.category}
            </p>
            <p>
              <strong>Details:</strong> {recommendation.details}
            </p>
            <p>
              <strong>Price:</strong> £{recommendation.price}
            </p>
            <Link
              to={`/booking?courseId=${recommendation.id}&courseType=${recommendation.category}&transmission=${transmission}&wantTheoryTest=${wantTheoryTest}&wantPracticalTest=${wantPracticalTest}`}
              state={{ message: linkedMessage || "" }}
            >
              <button
                className="btn book-now mb-4 mx-2"
                style={{ outline: "1px solid grey" }}
              >
                Book Now
              </button>
            </Link>
            <Link
              to={`/courses?courseType=${recommendation.category}`}
              onClick={saveTransmissionAndTest}
            >
              <button
                className="btn book-now mb-4 mx-2"
                style={{ outline: "1px solid grey" }}
              >
                Explore More Courses
              </button>
            </Link>
          </div>
        )}
        {showCrunchingNumbers && !recommendation && (
          <div>
            <h2 className="text-center">Hang On...</h2>
            {/* <div
              className="spinner-border text-primary text-center"
              role="status"
            >
              <span className="visually-hidden text-center">Loading...</span>
            </div> */}
          </div>
        )}
        {/* {recommendation && (
        <div>
          <h2>Recommended Course:</h2>
          <p>{recommendation}</p>
        </div>
      )} */}
      </div>
      {/* <div> */}
      {/* <p> */}
      {/* Previous Answers{" "} */}

      {/* </p> */}
      {/* {ageGroup && (
          <div>
            Age Group: {ageGroup}{" "}
            <span
              onClick={() => handleChangeAnswer("ageGroup", "")}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )}
        {transmission && (
          <div>
            Transmission: {transmission}{" "}
            <span
              onClick={() => handleChangeAnswer("transmission", "manual")}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )}
        {drivingSkills && (
          <div>
            Driving Skills: {drivingSkills}{" "}
            <span
              onClick={() => handleChangeAnswer("drivingSkills", "")}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )}
        {hasDrivenBefore !== null && (
          <div>
            Has Driven Before: {hasDrivenBefore ? "Yes" : "No"}{" "}
            <span
              onClick={() => handleChangeAnswer("hasDrivenBefore", null)}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )}
        {canDriveStraight !== null && (
          <div>
            Can Drive Straight: {canDriveStraight ? "Yes" : "No"}{" "}
            <span
              onClick={() => handleChangeAnswer("canDriveStraight", null)}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )}
        {canShiftGears !== null && (
          <div>
            Can Shift Gears: {canShiftGears ? "Yes" : "No"}{" "}
            <span
              onClick={() => handleChangeAnswer("canShiftGears", null)}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )}
        {steeringControl && (
          <div>
            Steering Control: {steeringControl}{" "}
            <span
              onClick={() => handleChangeAnswer("steeringControl", "")}
              className="text-end cursor-pointer"
            >
              Change
            </span>
          </div>
        )} */}
      {/* </div> */}
      {/* {answersOverview} */}
    </div>
  );
};

export default DrivingCourseRecommender;
