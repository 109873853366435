// src/components/ServicesPage.js
import React from "react";
import { Card } from "primereact/card";
import "../styles/ServicesPage.css";
import { Helmet } from "react-helmet";

const services = [
  {
    title: "Beginner Driving Course",
    description:
      "Perfect for new drivers. Learn the basics and build your confidence behind the wheel.",
    icon: "pi pi-car",
  },
  {
    title: "Intermediate Driving Course",
    description:
      "Ideal for those who have some experience but need more practice to pass their test.",
    icon: "pi pi-star",
  },
  //   {
  //     title: "Advanced Driving Course",
  //     description:
  //       "For experienced drivers looking to enhance their skills and knowledge.",
  //     icon: "pi pi-star",
  //   },
  {
    title: "Refresher Course",
    description:
      "Great for licensed drivers who want to refresh their driving skills.",
    icon: "pi pi-refresh",
  },
  {
    title: "Mock Driving Tests",
    description:
      "Simulate the real test environment to boost your confidence and readiness.",
    icon: "pi pi-check-circle",
  },
  //   {
  //     title: "Pass Plus Course",
  //     description:
  //       "Advanced training to enhance your driving skills and safety after passing your test.",
  //     icon: "pi pi-plus",
  //   },
];

const ServicesPage = () => {
  return (
    <div className="services-page">
      <Helmet>
        <title>Driving School Services | Pass Swiftly </title>
        <meta
          name="description"
          content="Explore Pass Swiftly’s range of driving services, including tailored courses for beginners, intermediates and refreshers. Learn with confidence through expert guidance and flexible options."
        />
      </Helmet>
      <h1>Our Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <Card key={index} className="service-card">
            <div className="service-icon">
              <i className={service.icon}></i>
            </div>
            <div className="service-content">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;
