import React, { useContext, useEffect, useState } from "react";
import "../styles/Home.css";
import HeroSection from "../Components/HeroSection";
import AfterHeroSection from "../Components/AfterHeroSection";
import BenifitsSection from "../Components/BenifitsSection";
import FindCourseSection from "../Components/FindCourseSection";
import Reviews from "../Components/Reviews";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { UserContext } from "../contexts/UserContext";
import { Outlet, useLocation } from "react-router-dom";
import MoneyBackGuarantee from "../Components/MoneyBackGuarantee";
import DiscountPromosSection from "../Components/DiscountPromosSection";
import VideosSection from "../Components/VideosSection";
import CourseRecommenderSection from "../Components/CourseRecommenderSection";
import BussinessReviewsSection from "../Components/BussinessReviewsSection";
import FAQSection from "../Components/FaqSection";
import { Helmet } from "react-helmet";

const Home = ({ handleLogOut }) => {
  // eslint-disable-next-line
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const location = useLocation();
  const { isLoggedIn } = useContext(UserContext);
  const isHomePage = location.pathname === "/";

  return (
    <div>
      <Helmet>
        <title>
          Top UK Driving School for Learners & Refreshers | Pass Swiftly
        </title>
        <meta
          name="description"
          content="Discover quality driving lessons with Pass Swiftly, a leading UK driving school offering beginner, intermediate, and refresher courses. Learn to drive confidently and safely!"
        />
      </Helmet>
      <Header handleLogOut={handleLogOut} isLoggedIn={isLoggedIn} />
      {isHomePage ? (
        <div style={{ marginTop: "-24px" }}>
          <HeroSection />
          <div
            className="mx-0"
            style={{
              backgroundColor: "#fff6e5",
            }}
          >
            <AfterHeroSection />
          </div>
          <div className={`home ${collapsed ? "collapsed" : ""}`}></div>
          <div>
            <BenifitsSection />
          </div>

          <FindCourseSection />
          <CourseRecommenderSection />
          <Reviews />
          <div className="container d-flex flex-wrap flex-row flex-container">
            <DiscountPromosSection />
            <MoneyBackGuarantee />
          </div>
          <VideosSection />
          <BussinessReviewsSection />
          <FAQSection />
        </div>
      ) : (
        <Outlet />
      )}
      <Footer />
    </div>
  );
};

export default Home;
